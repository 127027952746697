import type { HttpClient } from "~/infra/http/HttpClient";

export async function getPosByRetailChainsService(
  axiosInstance: HttpClient,
  searchable: string,
) {
  return axiosInstance.get(
    `pos?siv_contingency=0&retail_chains[]=${searchable}`,
  );
}
